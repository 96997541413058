import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import { DisplayNames } from 'Roblox';
import AvatarCaptionTitle from './AvatarCaptionTitle';
import AvatarCaptionFirstLine from './AvatarCaptionFirstLine';
import AvatarCaptionSecondLine from './AvatarCaptionSecondLine';
import AvatarCaptionFooter from './AvatarCaptionFooter';

function constructUsernameLabel(username) {
  return `@${username}`;
}

const AvatarCaption = ({
  name,
  nameLink,
  displayName,
  labelFirstLine,
  labelFirstLineLink,
  labelSecondLine,
  statusLink,
  statusLinkLabel,
  footer,
  hasMenu,
  truncateFirstLine
}) => {
  const classNames = ClassNames('avatar-card-caption', {
    'has-menu': hasMenu
  });
  const useAvatarCaptionFooter = typeof footer === 'string';
  return (
    <div className={classNames}>
      <span>
        {DisplayNames.Enabled() ? (
          <React.Fragment>
            <AvatarCaptionTitle title={displayName} titleLink={nameLink} />
            <div className='avatar-card-label'>{constructUsernameLabel(name)}</div>
          </React.Fragment>
        ) : (
          <AvatarCaptionTitle title={name} titleLink={nameLink} />
        )}
        <AvatarCaptionFirstLine
          firstLine={labelFirstLine}
          firstLineLink={labelFirstLineLink}
          isSingleLine={truncateFirstLine}
        />
        <AvatarCaptionSecondLine
          secondLine={labelSecondLine}
          status={statusLinkLabel}
          statusLink={statusLink}
        />
      </span>
      {useAvatarCaptionFooter ? <AvatarCaptionFooter footer={footer} /> : footer}
    </div>
  );
};

AvatarCaption.defaultProps = {
  name: '',
  nameLink: '',
  displayName: '',
  labelFirstLine: '',
  labelFirstLineLink: '',
  labelSecondLine: '',
  statusLink: '',
  statusLinkLabel: '',
  footer: undefined,
  hasMenu: false,
  truncateFirstLine: false
};
AvatarCaption.propTypes = {
  name: PropTypes.string,
  nameLink: PropTypes.string,
  displayName: PropTypes.string,
  labelFirstLine: PropTypes.string,
  labelFirstLineLink: PropTypes.string,
  labelSecondLine: PropTypes.string,
  statusLink: PropTypes.string,
  statusLinkLabel: PropTypes.string,
  footer: PropTypes.node,
  hasMenu: PropTypes.bool,
  truncateFirstLine: PropTypes.bool
};

export default AvatarCaption;
