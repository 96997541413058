import React from 'react';
import PropTypes from 'prop-types';

const AvatarCaptionTitle = ({ title, titleLink }) => {
  return titleLink ? (
    <a href={titleLink} className='text-overflow avatar-name'>
      {title}
    </a>
  ) : (
    <div className='text-overflow avatar-name'>{title}</div>
  );
};

AvatarCaptionTitle.defaultProps = {
  title: '',
  titleLink: ''
};
AvatarCaptionTitle.propTypes = {
  title: PropTypes.string,
  titleLink: PropTypes.string
};

export default AvatarCaptionTitle;
